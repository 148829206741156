.spinner,
.spinner:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.spinner {
    font-size: 10px;
    position: relative;
    border: 1.1em solid rgba(176, 25, 126, 0.2);
    border-left-color: #b0197e;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    display: inline-block;
}

.spinner-text {
    margin-left: 6px;
    line-height: 1.6em;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
