body {
    margin: 0;
    padding: 20px 0;
    font-family: sans-serif;
}

.main {
    margin-top: 100px;
}

.badge#machine-label {
    margin: 0 5px;
}

.rounded-circle {
    margin-right: 5px;
}

.log-filters label {
    margin-right: 15px;
}

.log-filters .form-check-input {
    margin-right: 0;
}

.log-filters h4 {
    margin: 25px 0 10px 0;
}

.drink-item {
    padding: 0.75rem 0.5rem;
}

.drink-item:not(.disabled):hover {
    background: rgba(0, 0, 0, 0.03);
}

.price-badge {
    margin-top: 4px;
    margin-right: 10px;
    font-size: 11px;
}

.collapse-filters {
    margin: 15px 0;
}

.card {
    margin-bottom: 20px;
}

.btn:disabled,
.disabled .text,
.disabled .drop,
.disabled .badge {
    opacity: 0.3;
    cursor: not-allowed;
}

.list-group-item.disabled,
.list-group-item:disabled {
    pointer-events: auto;
}

.pull-right {
    float: right;
}

.input-group {
    max-width: 300px;
    margin-right: 0;
}

.input-group input.form-control {
    height: auto;
    font-size: 0.815rem;
}

.input-group button {
    font-size: 0.7rem;
}

.input-group input[type='text'] {
    min-width: 50px;
}

#newItem {
    text-align: center;
    padding-top: 15px;
}

#toasts {
    position: absolute;
    bottom: 10px;
    left: 10px;
}
